module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-173074842-1","head":true,"exclude":["/404/","/success/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Saksham Kapoor","short_name":"Saksham Kapoor","start_url":"/","background_color":"#000000","theme_color":"#e22837","display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"16a42f5edd038cc8647a752b8f153687"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
